body, html {
  margin: 0;
  padding: 0;
  font-size: 50px;
}
body .container, html .container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: white;
  font-family: "Helvetica", helvetica, arial;
  text-shadow: 0 0 12px black;
}
body .Gif, html .Gif {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  display: flex;
}
body .Gif video, html .Gif video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
/*# sourceMappingURL=index.e8b963ab.css.map */
